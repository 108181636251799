.table-noheadertopborder thead th {
  border-top-width: 0px;
}

.table-clickable tbody td {
  cursor: pointer;
}

.space-children > * {
  margin-left: 7px;
}

.space-children > *:first-child {
  margin-left: 0px;
}

.clickable {
  cursor: pointer;
}

.strikethrough .fc-event-title {
  text-decoration: line-through !important;
}

.form-label {
  font-weight: bold;
}

.dropdown-toggle::after {
  display: none !important;
}

.fc-daygrid-day-number {
  cursor: pointer;
}

.activeDropDown > a {
  color: rgba(0, 0, 0, 0.9) !important;
}

.navbar-nav .nav-link {
  color: #05386b;
}

/* Buttons */
.btn {
  font-weight: 600 !important;
  border-width: 1px !important;
  font-style: normal !important;
  /* margin: 0.6rem 0.6rem !important; */

  padding: 0.6rem 1.2rem !important;
  /* border-radius: 4px !important; */
}
.btn-sm {
  padding: 0.6rem 1.2rem !important;
  /* border-radius: 4px !important; */
}
.btn-md {
  padding: 0.6rem 1.2rem !important;
  /* border-radius: 4px !important; */
}
.btn-lg {
  padding: 1rem 2.6rem;
  /* border-radius: 4px; */
}
.bg-primary {
  background-color: #05386b !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #05386b !important;
  border-color: #05386b !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #010d18 !important;
  border-color: #010d18 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #010d18 !important;
  border-color: #010d18 !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #e43f3f !important;
  border-color: #e43f3f !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #b31919 !important;
  border-color: #b31919 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #b31919 !important;
  border-color: #b31919 !important;
}

.btn-tertiary,
.btn-tertiary:active {
  background-color: rgb(142, 142, 142) !important;
  border-color: rgb(142, 142, 142) !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-tertiary:hover,
.btn-tertiary:focus,
.btn-tertiary.focus,
.btn-tertiary.active {
  color: #ffffff !important;
  background-color: rgb(92, 92, 92) !important;
  border-color: rgb(92, 92, 92) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-tertiary.disabled,
.btn-tertiary:disabled {
  color: #ffffff !important;
  background-color: rgb(92, 92, 92) !important;
  border-color: rgb(92, 92, 92) !important;
}
.btn-info,
.btn-info:active {
  background-color: #47b5ed !important;
  border-color: #47b5ed !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #148cca !important;
  border-color: #148cca !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #148cca !important;
  border-color: #148cca !important;
}
.btn-success,
.btn-success:active {
  background-color: green !important;
  border-color: green !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #ffffff !important;
  background-color: rgb(0, 86, 0) !important;
  border-color: rgb(0, 86, 0) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #2a747e !important;
  border-color: #2a747e !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #ffe161 !important;
  border-color: #ffe161 !important;
  color: #614f00 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #0a0800 !important;
  background-color: #ffd10a !important;
  border-color: #ffd10a !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #614f00 !important;
  background-color: #ffd10a !important;
  border-color: #ffd10a !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #ff9966 !important;
  border-color: #ff9966 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #ff5f0f !important;
  border-color: #ff5f0f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #ff5f0f !important;
  border-color: #ff5f0f !important;
}
.btn-white,
.btn-white:active {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
  color: #7a7a7a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #4f4f4f !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-white.disabled,
.btn-white:disabled {
  color: #7a7a7a !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.btn-black,
.btn-black:active {
  background-color: #232323 !important;
  border-color: #232323 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
/* I want to replace this with better css */
.btn-noshadow {
  box-shadow: none;
}
/* I want to replace this with better css */
.input-group-last {
  border-radius: 0.375rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.nav-link {
  /* font-family: Inter, sans-serif !important; */
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.backgroundcolor-blue {
  background-color: #05386b !important;
}
.backgroundcolor-gray {
  background-color: rgb(242, 242, 242) !important;
  /* background-color: rgb(250, 250, 250) !important; */
}
.backgroundcolor-white {
  background-color: white !important;
}
.color-blue {
  color: #05386b !important;
}
.color-red {
  color: red !important;
}
.color-white {
  color: white !important;
}

.card {
  border-width: 0px !important;
}
.display-1 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 5rem !important;
  font-weight: 400;
  line-height: 1.1 !important;
}
@media (max-width: 768px) {
  .display-1 {
    /* font-family: "Inter", sans-serif !important; */
    font-size: 4rem !important;
    font-weight: 400;
    line-height: 1.1 !important;
  }
}

.display-2 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 2.7rem !important;
  font-weight: 400;
  line-height: 1.1 !important;
}
@media (max-width: 768px) {
  .display-2 {
    /* font-family: "Inter", sans-serif !important; */
    font-size: 2rem !important;
    font-weight: 400;
    line-height: 1.1 !important;
  }
}
.display-3 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 1.7rem !important;
  font-weight: 400;
  line-height: 1.1 !important;
}
.display-4 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.display-5 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.display-6 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 1.8rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.display-6_5 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.display-7 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.display-9 {
  /* font-family: "Inter", sans-serif !important; */
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
  font-style: italic;
}
.display-bold {
  font-weight: 700 !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.btn-action {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
}
.header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.table-striped > tbody > tr:nth-child(odd) > td {
  color: #05386b;
}
.table {
  --bs-table-striped-bg: rgb(250, 250, 250) !important;
  --bs-table-striped-color: #05386b !important;
}

th,
td {
  color: #05386b;
}

.page {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

/* .shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
} */
.dropshadow {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
  /* Similar syntax to box-shadow */
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #05386b !important;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* make froala look same as homepage */
.fr-box.fr-basic .fr-element.fr-view {
  /* font-family: "Inter", sans-serif !important; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #05386b;
}

.fr-view h3 {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  line-height: 1.1 !important;
}

.fr-view p {
  margin-bottom: 5px;
}

.fr-view ul {
  margin-bottom: 5px;
}

.fr-view td,
.fr-view th,
.fr-view p,
.fr-view a,
.fr-view li {
  font-size: 1.1rem !important;
  line-height: 1.1 !important;
}

body {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
}

.btn-round {
  border-radius: 50px !important;
}

h1 {
  font-size: 2.7rem !important;
  font-weight: 700 !important;
  line-height: 1.1 !important;
}

h2 {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
}

h3 {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.product-page h3 {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  line-height: 1.1 !important;
}

.product-page p {
  margin-bottom: 5px;
}

.product-page ul {
  margin-bottom: 5px;
}

.product-page td,
.product-page th,
.product-page p,
.product-page a,
.product-page li {
  font-size: 1.1rem !important;
  line-height: 1.1 !important;
}

.line-height-1-1 {
  line-height: 1.1 !important;
}

.line-height-1-2 {
  line-height: 1.2 !important;
}

.line-height-1-3 {
  line-height: 1.3 !important;
}

.line-height-1-4 {
  line-height: 1.4 !important;
}

.line-height-1-5 {
  line-height: 1.5 !important;
}

.image-gallery .banner {
  height: 630px;
  width: 100%;
  object-fit: cover;
  min-height: 630px;
}

.image-gallery .banner-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
}
.image-gallery .banner-text {
  /* overflow-wrap: break-word; */

  text-wrap: balance !important;
}

@media (min-width: 577px) {
  .image-gallery .banner-button {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}
@media (max-width: 576px) {
  .image-gallery .banner-text h1 {
    font-size: 2.2rem !important;
    font-weight: 700 !important;
    line-height: 1.1 !important;
  }
  .image-gallery .banner-text p {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    line-height: 1.1 !important;
  }
  .image-gallery .banner-button {
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.order h4,
.invoice h4 {
  font-weight: bold !important;
}

.strike-through {
  text-decoration: line-through !important;
}

body {
  --scrollbar-width: calc(100vw - 100%);
}

.tile {
  width: calc((100vw - 23px) / 8);
  height: calc((100vw - 23px) / 8);
  border-radius: 10px;
  margin: 12px;
  display: inline-block;

  color: white;
  font-size: 26px;
  text-align: center;
  font-style: italic;
  font-weight: 700;
  vertical-align: top;
}

.tile-red {
  background: linear-gradient(
    0deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(190, 0, 0, 1) 79%
  );
}

.tile-blue {
  background: linear-gradient(
    0deg,
    rgba(99, 242, 199, 1) 0%,
    rgba(70, 170, 139, 1) 79%
  );
}

.tile-green {
  background: linear-gradient(
    0deg,
    rgba(135, 242, 25, 1) 0%,
    rgba(82, 169, 4, 1) 79%
  );
}

.tile-purple {
  background: linear-gradient(
    0deg,
    rgba(190, 67, 147, 1) 0%,
    rgba(136, 48, 105, 1) 79%
  );
}

.tile-yellow {
  background: linear-gradient(
    0deg,
    rgba(224, 191, 15, 1) 0%,
    rgba(212, 110, 17, 1) 79%
  );
}

.tile-gray {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(204, 204, 204, 1) 79%
  );
}

.top-0px {
  top: 0px !important;
}

.bottom-0px {
  bottom: 0px !important;
}

.image-gallery-slides {
  white-space: initial !important;
}

.table-striped > tbody > tr > td.color-red,
.table-striped > tfoot > tr:nth-of-type(odd) > td.color-red {
  color: red !important;
}

@media print {
  html {
    font-size: 8px;
  }
  .fixed-top {
    position: absolute !important;
  }
}

.hack {
  line-height: 36px !important;
}

.form-control-plaintext {
  font-size: 1rem;
}

@media (min-width: 992px) {
  .navbar-nav {
    align-items: center;
  }

  .nav-link {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
}
